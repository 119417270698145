import * as THREE from "three";
import React, { useCallback, useMemo, useRef } from "react";
import { useLoader, useFrame } from "@react-three/fiber";
import circle from "./circle.png"

export const Points = () => {
  const imgTex = useLoader(THREE.TextureLoader, circle);
  const bufferRef = useRef();
  const count = 100;
  const sep = 2;
  let t = 0;
  let f = 0.002;
  let a = 3;

  const graph = useCallback(
    (x, z) => {
      return Math.sin(f * (x ** 2 + z ** 2 + t)) * a;
    },
    [t, f, a]
  );

  const positions = useMemo(() => {
    let pos = [];

    for (let xi = 0; xi < count; xi++) {
      for (let zi = 0; zi < count; zi++) {
        let x = sep * (xi - count / 2);
        let z = sep * (zi - count / 2);
        let y = graph(x, z);
        pos.push(x, y, z);
      }
    }

    return new Float32Array(pos);
  }, [count, sep, graph]);

  useFrame(() => {
    const pos = bufferRef.current.array;
    t += 15;

    let i = 0;
    for (let xi = 0; xi < count; xi++) {
      for (let zi = 0; zi < count; zi++) {
        let x = sep * (xi - count / 2);
        let z = sep * (zi - count / 2);

        pos[i + 1] = graph(x, z);
        i += 3;
      }
    }

    bufferRef.current.needsUpdate = true;
  });

  return (
    <points>
      <bufferGeometry attach="geometry">
        <bufferAttribute
          ref={bufferRef}
          attachObject={["attributes", "position"]}
          array={positions}
          count={positions.length / 3}
          itemSize={3}
        />
      </bufferGeometry>
      <pointsMaterial
        attach="material"
        map={imgTex}
        color={0x00aaff}
        size={0.5}
        sizeAttenuation
        transparent={false}
        alphaTest={0.5}
        opacity={1.0}
      />
    </points>
  );
};
