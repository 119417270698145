
import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { Points } from "./points";

export default function Visiualizer({customStyle}) {
  return (
    <Canvas

    resize={{scroll: false}}
      colorManagement={false}
      camera={{ position: [100, 10, 0], fov: 75 }}
      style={customStyle ? {height: "48%"} : null}
    >
      <fog attach="fog" args={["#272730", 0, 200]} />
      <Suspense fallback={null}>
        <Points />
      </Suspense>
      <OrbitControls
        autoRotate
        autoRotateSpeed={0.4}
        maxDistance={100}
        maxPolarAngle={Math.PI / 2}
      />
    </Canvas>
  );
}
